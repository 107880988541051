import React, {useContext} from 'react';
import {AppContext} from '../contexts/appContext';
import RadioStation from '../components/radioStation/radio-station';

const Favourites: React.FC = () => {
    const {favouriteRadios} = useContext(AppContext);

    return (
        <>
            <div className='page-title'>Αγαπημένα</div>
            <div className='radio-station-list'>
                {favouriteRadios?.map((radio) => (
                    <RadioStation key = {radio.id} {...radio}/>
                ))}
            </div>
        </>
    );
};

export default Favourites;