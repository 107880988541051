import { useEffect, useCallback, useRef } from 'react';

// Custom Hook to handle the Intersection Observer logic
export function useIntersectionObserver(
  callback: () => void, 
  options: IntersectionObserverInit,
  delay: number | undefined) 
  {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const savedCallback = useRef(callback);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);


  useEffect(() => {
      savedCallback.current = callback;
  }, [callback]);

  //Create a memoized resetTimeout function
  const resetTimeout = useCallback(() => {
      if (timeoutId.current) {
          clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
          savedCallback.current();
      }, delay);

  }, [delay]);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {

          resetTimeout();
          
          // Stop observing once the item is fully visible
          if (observerRef.current && targetRef.current) {
            observerRef.current.unobserve(targetRef.current);
          }
        }
      });
    }, options);

    if (targetRef.current) {
      observerRef.current.observe(targetRef.current);
    }

    // Clean up the Intersection Observer when the component unmounts
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

    };
  }, [callback, options, delay, resetTimeout]);

  return targetRef;
}