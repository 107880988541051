import React, {useContext} from 'react';
import Popular from '../components/popular/popular';
import Widget from '../components/widget/widget';
import CategoryWidget from '../components/categoryWidget/category-widget';
import { AppContext } from '../contexts/appContext';

const Home: React.FC = () => {
    const {favouriteRadios, recentlyPlayedRadios} = useContext(AppContext);
    const categoryIds = ['greek-hits', 'greek-laiko', 'eclectic-ballads', 'various', 'traditional',
                        'adult-contemporary-dance', 'rock', 'sophisticated', 'news', 'sports', 'religious'];

    return (
        <>
            <Popular title='Top Σταθμοί'/>
            <div className='clearfix'>
                {(favouriteRadios && favouriteRadios.length > 0) &&
                    <div className='widget-wrapper'>
                        <Widget className='odd' items={favouriteRadios?.slice(0,5)} title='Αγαπημένα' seeAllLink='/favourites'/>
                    </div>
                }
                {(recentlyPlayedRadios && recentlyPlayedRadios.length > 0) &&
                    <div className='widget-wrapper'>  
                        <Widget className='even' items={recentlyPlayedRadios?.slice(0,5)} title='Άκουσες Πρόσφατα' seeAllLink='/recently-played'/>
                    </div>
                }
            </div>
            <div className='category-widgets'>
                <CategoryWidget numOfDisplayedItems={10} title='Ελληνικές Επιτυχίες' categoryId={categoryIds[0]}/>
                <CategoryWidget numOfDisplayedItems={10} title='Ελληνικά Λαϊκά' categoryId={categoryIds[1]}/>
                <CategoryWidget numOfDisplayedItems={10} title='Έντεχνη & Μπαλάντες' categoryId={categoryIds[2]}/>
                <CategoryWidget numOfDisplayedItems={10} title='Διάφορα' categoryId={categoryIds[3]}/>
                <CategoryWidget numOfDisplayedItems={10} title='Παραδοσιακά' categoryId={categoryIds[4]}/>
                <CategoryWidget numOfDisplayedItems={10} title='Ξένα Hits & Dance' categoryId={categoryIds[5]}/>
                <CategoryWidget numOfDisplayedItems={10} title='Ροκ' categoryId={categoryIds[6]}/>
                <CategoryWidget numOfDisplayedItems={10} title='Sophisticated' categoryId={categoryIds[7]}/>
                <CategoryWidget numOfDisplayedItems={10} title='Ειδήσεις & Μουσική' categoryId={categoryIds[8]}/>
                <CategoryWidget numOfDisplayedItems={10} title='Αθλητικά' categoryId={categoryIds[9]}/>
                <CategoryWidget numOfDisplayedItems={10} title='Θρησκευτικά' categoryId={categoryIds[10]}/>
                <CategoryWidget numOfDisplayedItems={10} title='Ειδήσεις & Μουσική' categoryId={categoryIds[8]}/>
            </div>
        </>
    );
};

export default Home;