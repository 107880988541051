import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import { RadioProps } from '../../contexts/appContext';
import WidgetItem from './widgetItem';
import {useTimeout} from '../../hooks/useTimeout';
import './widget.css';

interface WidgetProps {
    title: string,
    seeAllLink: string,
    className?: 'odd' | 'even';
    items: RadioProps[];
}

const Widget: React.FC<WidgetProps> = ({
    title, 
    seeAllLink, 
    className = '',
    items
}) => {
    
    const [isWidgetItemLoading, setIsWidgetItemLoading] = useState<boolean>(false);

    useTimeout(() => {
        setIsWidgetItemLoading(true);
    }, 500);

    const renderSkeletonWidgetItem = (widgetId: string, num: number) => {
        return (
            <div key={widgetId} className='skeleton-widget-item'>
                <div className='skeleton-number'>{num+1}</div>
                <div className='skeleton-icon'></div>
                <div className='skeleton-name'></div>
                <div className='skeleton-category'></div>
            </div>
        )
    }

    return (
        <div className={`widget ${className}`}>
            <div className='widget-header clearfix'>
                <div className='widget-title'>{title}</div>
                <div className='see-all-link'>
                    <NavLink to = {seeAllLink}>Όλα</NavLink>
                </div>
            </div>
            <div className='widget-body'>
                {items?.map((item, index) => (
                    (!isWidgetItemLoading) ? renderSkeletonWidgetItem(item.id, index) :
                    (<WidgetItem key = {item.id} item = {item} num = {index}/>)
                ))}
            </div>
        </div>
    )
};

export default Widget;