import axios from "axios";
import { 
    Sfera, 
    Rythmos, 
    LoveFm, 
    KlikFm, 
    Zoo,
    Campos,
    Cosmoradio,
    Melody,
    Libero, 
    Metropolis,
    Radio,
    HeadPhones
} from '../assets/index';

import { RadioProps } from '../contexts/appContext';

const radios: RadioProps [] = [
    { 
        id:'love', 
        name: 'Love FM', 
        iconUrl: LoveFm, 
        category: 'Ελληνικές Επιτυχίες', 
        categorySlug: 'greek-hits', 
        location: 'Αθήνα', 
        locationSlug: 'athens',
        city: '',
        streamUrl: 'http://loveradio.live24.gr:80/loveradio-1000',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'klik', 
        name: 'Klik FM', 
        iconUrl: KlikFm, 
        category: 'Ελληνικά Λαϊκά', 
        categorySlug: 'greek-laiko', 
        location: 'Κύπρος', 
        locationSlug: 'cyprus',
        city: '',
        streamUrl: 'http://radio.wavespeed.net/klikfm.mp3',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'rythmos', 
        name: 'Rythmos', 
        iconUrl: Rythmos, 
        category: 'Έντεχνη & Μπαλάντες', 
        categorySlug: 'eclectic-ballads', 
        location: 'Αθήνα', 
        locationSlug: 'athens',
        city: '',
        streamUrl: 'https://24streams.me/8888/stream',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'sfera', 
        name: 'Sfera', 
        iconUrl: Sfera, 
        category: 'Διάφορα', 
        categorySlug: 'various', 
        location: 'Αθήνα', 
        locationSlug: 'athens',
        city: '',
        streamUrl: 'http://sfera.live24.gr/sfera4132',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'zoo', 
        name: 'Zoo Radio', 
        iconUrl: Zoo, 
        category: 'Παραδοσιακά', 
        categorySlug: 'traditional', 
        location: 'Θεσσαλονίκη', 
        locationSlug: 'thessaloniki',
        city: '',
        streamUrl: 'https://metromedia.live24.gr/zoo908thess',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'cosmoradio', 
        name: 'Cosmoradio', 
        iconUrl: Cosmoradio, 
        category: 'Ξένη Μουσική & Dance', 
        categorySlug: 'adult-contemporary-dance', 
        location: 'Θεσσαλονίκη', 
        locationSlug: 'thessaloniki',
        city: '',
        streamUrl: 'http://66.240.211.156:8012',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'metropolis', 
        name: 'Metropolis 95,5', 
        iconUrl: Metropolis, 
        category: 'Ροκ', 
        categorySlug: 'rock', 
        location: 'Θεσσαλονίκη', 
        locationSlug: 'thessaloniki',
        city: '',
        streamUrl: 'https://metromedia.live24.gr/metropolis955thess',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'libero', 
        name: 'Libero 107,4', 
        iconUrl: Libero, 
        category: 'Sophisticated', 
        categorySlug: 'sophisticated', 
        location: 'Θεσσαλονίκη', 
        locationSlug: 'thessaloniki',
        city: '',
        streamUrl: 'http://eco.onestreaming.com:8081/;',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'melody', 
        name: 'Melody 94,9', 
        iconUrl: Melody, 
        category: 'Ειδήσεις & Μουσική', 
        categorySlug: 'news', 
        location: 'Ήπειρος', 
        locationSlug: 'epirus',
        city: '',
        streamUrl: 'http://109.123.114.30:7005/live?type=.flv',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'campos', 
        name: 'Campos 97,8', 
        iconUrl: Campos, 
        category: 'Αθλητικά Νέα', 
        categorySlug: 'sports', 
        location: 'Θεσσαλία',
        locationSlug: 'thessaly',
        city: '',
        streamUrl: 'http://rockradio.gr:1111/;',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'status', 
        name: 'Status 94,2', 
        iconUrl: Radio, 
        category: 'Θρησκευτικά', 
        categorySlug: 'religious', 
        location: 'Θράκη',
        locationSlug: 'thrace',
        city: '',
        streamUrl: 'http://eco.onestreaming.com:8131/;',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'radio-me', 
        name: 'Radio Me 88,4', 
        iconUrl: Radio, 
        category: 'Ελληνικές Επιτυχίες', 
        categorySlug: 'greek-hits', 
        location: 'Κρήτη',
        locationSlug: 'crete',
        city: '',
        streamUrl: 'http://i4.streams.ovh:9078/live',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'power-fm', 
        name: 'Power FM 90,2', 
        iconUrl: Radio, 
        category: 'Ελληνικά Λαϊκά', 
        categorySlug: 'greek-laiko', 
        location: 'Μακεδονία',
        locationSlug: 'macedonia',
        city: '',
        streamUrl: 'http://radio.power926.gr:55479/Powerfm',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'astra', 
        name: 'Astra 93,2', 
        iconUrl: Radio, 
        category: 'Έντεχνη & Μπαλάντες', 
        categorySlug: 'eclectic-ballads', 
        location: 'Αιγαίο',
        locationSlug: 'aegean',
        city: '',
        streamUrl: 'http://www.media.cy.net/astra.asx',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'lefkatas', 
        name: 'Lefkatas 90,5', 
        iconUrl: Radio, 
        category: 'Διάφορα', 
        categorySlug: 'various', 
        location: 'Ιόνιο',
        locationSlug: 'ionian',
        city: '',
        streamUrl: 'http://75.75.226.26:7099',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'fly-radio', 
        name: 'Fly Radio 89,7', 
        iconUrl: Radio, 
        category: 'Παραδοσιακά', 
        categorySlug: 'traditional', 
        location: 'Πελοπόννησος',
        locationSlug: 'peloponnesus',
        city: '',
        streamUrl: 'http://sc2.streamwithq.com:8040/stream/;',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'dalkas', 
        name: 'Dalkas 88,2', 
        iconUrl: Radio, 
        category: 'Ξένη Μουσική & Dance', 
        categorySlug: 'adult-contemporary-dance', 
        location: 'Στερεά Ελλάδα',
        locationSlug: 'sterea',
        city: '',
        streamUrl: 'https://stream.radiojar.com/pr9r38w802hvv',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'zenith', 
        name: 'Zenith 96,4', 
        iconUrl: Radio, 
        category: 'Ροκ', 
        categorySlug: 'rock', 
        location: 'Κύπρος',
        locationSlug: 'cyprus',
        city: '',
        streamUrl: 'http://www.radio.cy.net/zenith.asx',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'super-fm', 
        name: 'Super FM 104,8', 
        iconUrl: Radio, 
        category: 'Sophisticated', 
        categorySlug: 'sophisticated', 
        location: 'Κύπρος',
        locationSlug: 'cyprus',
        city: '',
        streamUrl: 'http://212.83.138.48:8530/stream?type=http&nocache=1126',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'mousikos', 
        name: 'Mousikos', 
        iconUrl: Radio, 
        category: 'Ειδήσεις & Μουσική', 
        categorySlug: 'news', 
        location: 'Internet Radios',
        locationSlug: 'internet',
        city: '',
        streamUrl: 'http://netradio.live24.gr:80/mousikos986',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'radio-in', 
        name: 'RadioIn', 
        iconUrl: Radio, 
        category: 'Αθλητικά Νέα', 
        categorySlug: 'sports', 
        location: 'Internet Radios',
        locationSlug: 'internet',
        city: '',
        streamUrl: 'https://rdst.win:48383/stream',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
    { 
        id:'play-lounge', 
        name: 'Play Lounge', 
        iconUrl: Radio, 
        category: 'Θρησκευτικά', 
        categorySlug: 'religious', 
        location: 'Internet Radios',
        locationSlug: 'internet',
        city: '',
        streamUrl: 'http://www.oserver.info/tunein.php/mora/playlist.asx',
        telephone: '',
        fax: '',
        address: '',
        email: ''
    },
];

export function getRadioById(radioId: string){
    return radios.find(({id}) => id === radioId)
}

export function getRadiosByName(name: string) {
    let nameLower = name.toLocaleLowerCase();
    const regex = new RegExp(nameLower, 'u');
    return radios.filter(item => item.name.toLocaleLowerCase().match(regex));
}

export function getRadiosByCategoryId(category: string){
    return radios.filter((item) => item.categorySlug === category);
}

export function getRadiosByLocationId(location: string){
    return radios.filter((item) => item.locationSlug === location);
}

export function getAllRadios(){
    return radios;
}

export function getRandomRadios(numOfItems: number){
    if (numOfItems >= radios.length) {
        return radios;
    }

    const randomItems: RadioProps[] = [];
    const indices = new Set();

    while (indices.size < numOfItems) {
        const randomIndex = Math.floor(Math.random() * radios.length);
        indices.add(randomIndex);
    }

    for (let index of indices) {
        randomItems.push(radios[index as number]);
    }

    return randomItems;
}

export function convertStringToIcon(radioName: string | undefined){
    if (radioName === 'Radio')
        return HeadPhones;
    if (radioName === 'LoveFm')
        return LoveFm;
    if (radioName === 'KlikFm')
        return KlikFm;
    if (radioName === 'Rythmos')
        return Rythmos;
    if (radioName === 'Sfera')
        return Sfera;
    if (radioName === 'Zoo')
        return Zoo;
    if (radioName === 'Cosmoradio')
        return Cosmoradio;
    if (radioName === 'Metropolis')
        return Metropolis;
    if (radioName === 'Libero')
        return Libero;
    if (radioName === 'Melody')
        return Melody;
    if (radioName === 'Campos')
        return Campos;
    if (radioName === 'Melody')
        return Melody;
}

const URL = `${process.env.REACT_APP_FUNCTION_API_URL}/`.toString();

const api = axios.create({
    baseURL: URL
})

export default api;