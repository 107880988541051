import React, {useContext} from 'react';
import { AppContext } from '../contexts/appContext';
import RadioStation from '../components/radioStation/radio-station';

const RecentlyPlayed: React.FC = () => {
    const {recentlyPlayedRadios} = useContext(AppContext);

    return (
        <>
            <div className='page-title'>Άκουσες Πρόσφατα</div>
            <div className='radio-station-list'>
                {recentlyPlayedRadios?.map((radio) => (
                    <RadioStation key = {radio.id} {...radio}/>
                ))}
            </div>
        </>
    );
};

export default RecentlyPlayed;