import React, {useState, useEffect} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navigation from '../components/navigation/navigation';
import Shortcuts from '../components/shortcuts/shortcuts';
import Header from '../components/header/header';
import Player2 from '../components/player/player2';
import {Toaster} from 'react-hot-toast';
import useDeviceDetect from '../hooks/useDeviceDetect';
import {trackPageView} from '../utils/gtm';

const Layout: React.FC = () => {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    
    const handleMenu = (state: boolean) => {
        setIsMenuOpen(state);
    }

    const {isMobile} = useDeviceDetect();

    useEffect(() => {
        setIsMenuOpen(false);
        trackPageView(location.pathname);
    }, [location])

    return (
        <div className='page-layout-wrapper'>
            <Toaster position='bottom-right' gutter={14} toastOptions={{className: 'toast', duration: 4000, iconTheme: {primary: '#00C33E', secondary: '#FFFFFF'}}}/>
            <Header isMobileMenuOpen = {isMenuOpen} toggleMobileMenu={handleMenu}/>
            <Navigation isMobileMenuOpen = {isMenuOpen}/>
            <div className={`main ${isMobile ? 'mob' : ''}`}>
                {isMenuOpen &&
                    <div className='main-inner'></div>
                }
                <Outlet/>
            </div> 
            <div className='player-widget'>
                <Player2/>
            </div>
            <div className='shortcut-wrapper'>
                <Shortcuts/>
            </div>
        </div>
    );
};


export default Layout;