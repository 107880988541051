import {useState, useEffect} from "react";

export default function useDeviceDetect() {
    const [isMobile, setMobile] = useState<boolean>(false);

    useEffect(() => {
        const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
        const mobile = Boolean(
          userAgent.match(
            /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
          )
        );
        
        if (mobile){
          setMobile(mobile);
        }
        else {

          const isIpadNewer = Boolean(userAgent.match('Macintosh')) && navigator.maxTouchPoints > 1

          setMobile(isIpadNewer);

        }
    }, []);

    return {
        isMobile
    };
}