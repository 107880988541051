import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation  } from 'react-router-dom';
import { AppContext, RadioProps } from '../../contexts/appContext';
import { useTimeout } from '../../hooks/useTimeout';
import { getRandomNumberInRange } from '../../utils/utils';
import toast from 'react-hot-toast';
import './radio-station.css';
import {trackCustomEvent} from '../../utils/gtm';

const RadioStation: React.FC<RadioProps> = ({
    id,
    name,
    streamUrl,
    city,
    telephone,
    categorySlug,
    category,
    location,
    locationSlug,
    fax,
    address,
    email,
    iconUrl
}) => {

    const currentLocation = useLocation();
    let isCategoryLink =  false;
    let isLocationLink =  false;

    const {toggleFavouriteRadio, 
        favouriteRadios, 
        currentRadio,
        updateCurrentRadio} = useContext(AppContext);

    const [isPlayingMode, setIsPlayingMode] = useState<boolean>(currentRadio?.isPlaying as boolean);
    const [isComponentLoading, setIsComponentLoading] = useState<boolean>(true);

    if (currentLocation.pathname?.split('/')?.length > 0) {
        const urlSegment = currentLocation.pathname.split('/')[1];
        if( urlSegment !== null && urlSegment !== undefined) {
            if (urlSegment === 'categories') {
                isCategoryLink = true;
            }
            if (urlSegment === 'location') {
                isLocationLink = true;
            }

        }
    }

    const handleFavorite = (radio: RadioProps, operation: 'add' | 'remove') => {
        let message = (operation === 'add') 
            ? `Προστέθηκε ο ${radio.name} στα αγαπημένα` 
            : (operation === 'remove') 
                ? `Αφαιρέθηκε ο ${radio.name} από τα αγαπημένα` 
                : '';
        toast.success(message);
        toggleFavouriteRadio?.(radio, operation);
    }

    const item = {
        id: id,
        name: name,
        streamUrl : streamUrl,
        city : city,
        telephone: telephone,
        categorySlug: categorySlug,
        category: category,
        location: location,
        locationSlug: locationSlug,
        fax: fax,
        address: address,
        email: email,
        iconUrl: iconUrl
    } as RadioProps

    const isRadioFavourite = favouriteRadios.some((item) => item.id === id);

    const handleSelectedRadio = (streamUrl: string) => {
        if(currentRadio === undefined){
            updateCurrentRadio?.(isPlayingMode, item);
            setIsPlayingMode(!isPlayingMode);

            trackCustomEvent({eventName:`Station - ${item.name}`});
        }
        else if (currentRadio?.id === item.id){
            updateCurrentRadio?.(isPlayingMode, item);
            setIsPlayingMode(!isPlayingMode);
        }
        else if (currentRadio?.id !== item.id){
            updateCurrentRadio?.(false, item);
            setIsPlayingMode(true);

            trackCustomEvent({eventName:`Station - ${item.name}`});
        }
    }

    useEffect(() => {
        setIsPlayingMode(currentRadio?.isPlaying as boolean);
    }, [currentRadio?.isPlaying])

    useTimeout(() => {
        setIsComponentLoading(false);
    }, getRandomNumberInRange(150, 400))

    const renderSkeleton = () => {
        return(
            <div className='skeleton-wrapper'>
                <div className='skeleton-item'>
                    <div className='skeleton skeleton-image'></div>
                    <div className='skeleton skeleton-row' style={{width: '60%', height: '16px'}}></div>
                    <div className='skeleton skeleton-row' style={{width: '70%', height: '22px'}}></div>
                    <div className='skeleton skeleton-row' style={{width: '40%', height: '16px'}}></div>
                </div>
            </div>
        )
    }

    return (
        (isComponentLoading) ? (renderSkeleton()) :
        (<div className='radio-item'>
            <div className='radio-icon'>
                {/* <img src={convertStringToIcon(iconUrl)} alt={name} title={name}/> */}
                <img src={iconUrl} alt={name} title={name}/>
                <div className={`cover-icon ${(currentRadio?.id === item.id && isPlayingMode === true) ? 'active' : ''}`}></div>
                <div className={`action ${(currentRadio?.id === item.id && isPlayingMode === true) ? 'playing active' : ''}`} title={(currentRadio?.id === item.id && isPlayingMode === true) ? 'Pause' : 'Play'} onClick={ () => handleSelectedRadio(item.streamUrl as string)}>
                    {(currentRadio === undefined || currentRadio?.id !== item.id) &&
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10"/>
                            <path d="M15.4137 10.941C16.1954 11.4026 16.1954 12.5974 15.4137 13.059L10.6935 15.8458C9.93371 16.2944 9 15.7105 9 14.7868L9 9.21316C9 8.28947 9.93371 7.70561 10.6935 8.15419L15.4137 10.941Z"/>
                        </svg>
                    }
                    {(currentRadio?.id === item.id && isPlayingMode === false) &&
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10"/>
                            <path d="M15.4137 10.941C16.1954 11.4026 16.1954 12.5974 15.4137 13.059L10.6935 15.8458C9.93371 16.2944 9 15.7105 9 14.7868L9 9.21316C9 8.28947 9.93371 7.70561 10.6935 8.15419L15.4137 10.941Z"/>
                        </svg>
                    }
                    {(currentRadio?.id === item.id && isPlayingMode === true) && 
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10"/>
                            <path d="M8 9.5C8 9.03406 8 8.80109 8.07612 8.61732C8.17761 8.37229 8.37229 8.17761 8.61732 8.07612C8.80109 8 9.03406 8 9.5 8C9.96594 8 10.1989 8 10.3827 8.07612C10.6277 8.17761 10.8224 8.37229 10.9239 8.61732C11 8.80109 11 9.03406 11 9.5V14.5C11 14.9659 11 15.1989 10.9239 15.3827C10.8224 15.6277 10.6277 15.8224 10.3827 15.9239C10.1989 16 9.96594 16 9.5 16C9.03406 16 8.80109 16 8.61732 15.9239C8.37229 15.8224 8.17761 15.6277 8.07612 15.3827C8 15.1989 8 14.9659 8 14.5V9.5Z" />
                            <path d="M13 9.5C13 9.03406 13 8.80109 13.0761 8.61732C13.1776 8.37229 13.3723 8.17761 13.6173 8.07612C13.8011 8 14.0341 8 14.5 8C14.9659 8 15.1989 8 15.3827 8.07612C15.6277 8.17761 15.8224 8.37229 15.9239 8.61732C16 8.80109 16 9.03406 16 9.5V14.5C16 14.9659 16 15.1989 15.9239 15.3827C15.8224 15.6277 15.6277 15.8224 15.3827 15.9239C15.1989 16 14.9659 16 14.5 16C14.0341 16 13.8011 16 13.6173 15.9239C13.3723 15.8224 13.1776 15.6277 13.0761 15.3827C13 15.1989 13 14.9659 13 14.5V9.5Z" />
                        </svg>
                    }
                </div>
            </div>
            <div className='radio-name'>{name}</div>
            <div className='radio-category'>
                {!isCategoryLink &&
                    <Link to = {`/categories/${categorySlug}`} aria-label={category} title={category}>
                        {category}
                    </Link>
                }
                {isCategoryLink &&
                    <span>{category}</span>
                }
            </div>
            <div className='radio-location'>
                {!isLocationLink &&
                    <Link to = {`/location/${locationSlug}`} aria-label={location} title={location}>
                        {location}
                    </Link>
                }
                {isLocationLink &&
                    <span>{location}</span>
                }
            </div>
            <div className='cover'></div>
            {!isRadioFavourite &&
                <div className='is-favourite' title='Add to your favourites' onClick={ () => handleFavorite(item, 'add')}>
                    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m12.62 20.81c-.34.12-.9.12-1.24 0-2.9-.99-9.38-5.12-9.38-12.12002 0-3.09 2.49-5.59 5.56-5.59 1.82 0 3.43.88 4.44 2.24 1.01-1.36 2.63-2.24 4.44-2.24 3.07 0 5.56 2.5 5.56 5.59 0 7.00002-6.48 11.13002-9.38 12.12002z"/>
                    </svg>
                </div>
            }
            {isRadioFavourite &&
                <div className='is-favourite selected' title='Remove from your favourites' onClick={ () => handleFavorite(item, 'remove')}>
                    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m12.62 20.81c-.34.12-.9.12-1.24 0-2.9-.99-9.38-5.12-9.38-12.12002 0-3.09 2.49-5.59 5.56-5.59 1.82 0 3.43.88 4.44 2.24 1.01-1.36 2.63-2.24 4.44-2.24 3.07 0 5.56 2.5 5.56 5.59 0 7.00002-6.48 11.13002-9.38 12.12002z"/>
                    </svg>
                </div>
            }     
        </div>)
    );
};

export default RadioStation;