type WindowWithDataLayer = Window & {
    gtag: Function;
};
  
declare const window: WindowWithDataLayer;
  
type TrackerProps = {
    eventName: string;
};

export const tracker2 = () => {
    return window.gtag;
};

export const trackCustomEvent = ({ eventName }: TrackerProps) => {
    window.gtag('event', eventName);
};

export const trackPageView = (pathname: string) => {
    window.gtag('event', 'page_view', {
        page_title: getPageTitle(pathname)
    });
};



const getPageTitle = (pathname: string) => {
    var paths =  [
        { key: '/', value: 'Αρχική'},
        { key: '/categories', value: 'Ανά Κατηγορία'},
        { key:'/location', value: 'Ανά Περιοχή'},
        { key:'/popular', value: 'Δημοφιλή'},
        { key:'/recently-played', value: 'Άκουσες Πρόσφατα'},
        { key:'/favourites', value: 'Αγαπημένα'},
        { key:'/categories/greek-hits' , value: 'Κατηγορία - Ελληνικές Επιτυχίες'},
        { key:'/categories/greek-laiko' , value: 'Κατηγορία - Ελληνικά Λαϊκά'},
        { key:'/categories/eclectic-ballads' , value: 'Κατηγορία - Έντεχνη & Μπαλάντες'},
        { key:'/categories/various' , value: 'Κατηγορία - Διάφορα'},
        { key:'/categories/traditional' , value: 'Κατηγορία - Παραδοσιακά'},
        { key:'/categories/adult-contemporary-dance' , value: 'Κατηγορία - Ξένη Μουσική & Dance'},
        { key:'/categories/rock' , value: 'Κατηγορία - Ροκ'},
        { key:'/categories/sophisticated' , value: 'Κατηγορία - Sophisticated'},
        { key:'/categories/news' , value: 'Κατηγορία - Ειδήσεις & Μουσική'},
        { key:'/categories/sports' , value: 'Κατηγορία - Αθλητικά Νέα'},
        { key:'/categories/religious' , value: 'Κατηγορία - Θρησκευτικά'},
        { key:'/location/athens' , value: 'Περιοχή - Αθήνα'},
        { key:'/location/thessaloniki' , value: 'Περιοχή - Θεσσαλονίκη'},
        { key:'/location/epirus' , value: 'Περιοχή - Ήπειρος'},
        { key:'/location/thessaly' , value: 'Περιοχή - Θεσσαλία'},
        { key:'/location/thrace' , value: 'Περιοχή - Θράκη'},
        { key:'/location/crete' , value: 'Περιοχή - Κρήτη'},
        { key:'/location/macedonia' , value: 'Περιοχή - Μακεδονία'},
        { key:'/location/aegean' , value: 'Περιοχή - Αιγαίο'},
        { key:'/location/ionian' , value: 'Περιοχή - Ιόνιο'},
        { key:'/location/peloponnesus' , value: 'Περιοχή - Πελοπόννησος'},
        { key:'/location/sterea' , value: 'Περιοχή - Στερεά Ελλάδα'},
        { key:'/location/cyprus' , value: 'Περιοχή - Κύπρος'},
        { key:'/location/internet' , value: 'Περιοχή - Internet Radios'}
    ]

    var val = paths.find(function(o){ return o.key === pathname})?.value;

    if (val === undefined) {
        val = "NOT FOUND"
    }

    return val;
}