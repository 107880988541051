import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import Search from '../search/search';
import './header.css';

interface HeaderProps {
    isMobileMenuOpen?: boolean
    toggleMobileMenu?: (state: boolean) => void
}

const Header: React.FC<HeaderProps> = ({isMobileMenuOpen = false, toggleMobileMenu}) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(isMobileMenuOpen);

    useEffect(() => {
        setIsMenuOpen(isMobileMenuOpen);
    }, [isMobileMenuOpen])

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        toggleMobileMenu?.(!isMenuOpen);
    }

    return (
        <div className='header'>
            <div className='logo'>
                <NavLink to = "/" aria-label='Home' title='Home'>
                    Gr.NET Radio
                </NavLink>
            </div>
            <div className='header-inner'>
                <div className='search-wrapper'>
                    <Search/>
                </div>
            </div>
            <div className='menu-icon' onClick={() => toggleMenu()} title={`${!isMenuOpen ? 'Show' : 'Hide'} menu`}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6H20M4 12H14M4 18H9"/>
                </svg>
            </div>
            {/* <button className='toggleMenuBtn' onClick={toggleMenu}>Show Mobile Menu</button> */}
        </div>
    )
};

export default Header;