import React, {useState, useEffect, useRef, ChangeEvent, FocusEvent} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {getRadiosByName} from '../../api/api';
import {getRadioCategoriesByName} from '../../api/radioCategories';
import {getRadioLocationsByName} from '../../api/radioLocations';
import './search.css';
import {RadioProps} from '../../contexts/appContext';

const Search: React.FC = () => {
    const location = useLocation();

    const [value, setValue] = useState<string>('');
    //const [isSearching, setIsSearching] = useState<boolean>(false);

    const [radios, setRadios] = useState<RadioProps[]>([]);
    const [locations, setLocations] = useState<any>();
    const [categories, setCategories] = useState<any>();
    

    const overlayRef = useRef<HTMLDivElement>(null);
    const searchResultsRef = useRef<HTMLDivElement>(null);
    const searchParentRef = useRef<HTMLDivElement>(null);
    const inputSearchRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        searchResultsRef.current?.classList.remove('active');

        let searchTerm = event.target.value;
        setValue(searchTerm);
        
        if (searchTerm === '') {
            setRadios([]);
            setLocations([]);
            setCategories([]);
            return;
        }

        let radios = getRadiosByName(searchTerm);
        let locations = getRadioLocationsByName(searchTerm);
        let categories = getRadioCategoriesByName(searchTerm);

        

        setRadios(radios);
        setLocations(locations);
        setCategories(categories);
    
        // if(searchParentRef.current) {
        //     searchParentRef.current.scrollTop = 0;
        // }

        searchResultsRef.current?.classList.add('active');
    }

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        //setIsSearching(true);

        if (overlayRef.current) {
            overlayRef.current.classList.add('active');
        }
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        
    }

    useEffect(() => {
        // Function to handle clicks outside the target div
        const handleClickOutside = (event: MouseEvent) => {
          if (searchParentRef.current && !searchParentRef.current.contains(event.target as Node)) {
            clearSearchState();
          } else {
            inputSearchRef.current?.classList.add('focus');
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        // Clean up the event listener on component unmount
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    useEffect(() => {
        clearSearchState();
    }, [location]);  

    const clearSearchState = () => {
        setValue('');
        searchResultsRef.current?.classList.remove('active');
        setRadios([]);
        setLocations([]);
        setCategories([]);
        inputSearchRef.current?.classList.remove('focus');
        //setIsSearching(false);

        if (overlayRef.current) {
            setTimeout(() => {
                overlayRef.current?.classList.remove('active');
            }, 200);
        }
    }

    const handleClickOverlay = () => {
        clearSearchState();
    }    

    return (
        <>
            <div ref={searchParentRef}>
                <div className='search-wrapper-inner'>
                    <input ref={inputSearchRef} type='text' 
                    spellCheck='false' 
                    autoComplete='false' 
                    autoCorrect='false'
                    placeholder='Αναζήτηση σταθμού, περιοχής ή κατηγορίας...' 
                    value={value} 
                    className='input-search'
                    onFocus={handleFocus} 
                    onChange={handleChange} 
                    onBlur={handleBlur}/>
                </div>
                {(radios?.length > 0 || locations?.length > 0 || categories?.length > 0) &&
                <div ref={searchResultsRef} className='search-results active'>
                    {radios?.length > 0 &&
                        (<div className='radios'>
                            {radios.map((item, index) => (
                                <div className='result' key={index}>
                                    <div className='radio-icon'>
                                        <img src={item.iconUrl} alt={item.name}/>
                                    </div>
                                    <div className='radio-name'>{item.name}</div>
                                    <div className='radio-meta'>
                                        <NavLink title={item.location} to = {`/location/${item.locationSlug}`}>{item.location}</NavLink> / <NavLink title={item.category} to = {`/categories/${item.categorySlug}`}>{item.category}</NavLink>
                                    </div>
                                </div>
                            ))}
                        </div>)
                    }
                    {locations?.length > 0 &&
                        (<div className='locations'>
                            <div className='title'>
                                <div className='icon'>
                                    <svg className='location-icon' fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path d="m15.6491 21.4102c-.43 0-.86-.09-1.21-.26l-5.25004-2.63c-.3-.15-.89-.14-1.18.03l-2.36 1.35c-1.02.58-2.07.66-2.86.19-.8-.46-1.25-1.4-1.25-2.58v-9.72004c0-.91.6-1.94 1.39-2.39l4.33-2.48c.73-.42 1.84-.45 2.59-.07l5.25004 2.63c.3.15.88.13 1.18-.03l2.35-1.34c1.02-.58 2.07-.66 2.86-.19.8.46 1.25 1.4 1.25 2.58v9.73004c0 .91-.6 1.94-1.39 2.39l-4.33 2.48c-.38.2-.88.31-1.37.31zm-7.01004-4.49c.43 0 .86.09 1.21.26l5.25004 2.63c.3.15.88.13 1.18-.03l4.33-2.48c.32-.18.63-.72.63-1.08v-9.73004c0-.63-.18-1.1-.51-1.28-.32-.18-.82-.11-1.36.2l-2.35 1.34c-.73.42-1.84.45-2.59.07l-5.25004-2.63c-.3-.15-.88-.13-1.18.03l-4.33 2.48c-.32.18-.63.72-.63 1.09v9.73004c0 .63.18 1.1.5 1.28.32.19.82.11 1.37-.2l2.35-1.34c.39-.23.89-.34 1.38-.34z"/>
                                            <path d="m8.56055 17.75c-.41 0-.75-.34-.75-.75v-13c0-.41.34-.75.75-.75s.75.34.75.75v13c0 .41-.34.75-.75.75z"/>
                                            <path d="m15.7305 20.7501c-.41 0-.75-.34-.75-.75v-13.37998c0-.41.34-.75.75-.75s.75.34.75.75v13.37998c0 .41-.34.75-.75.75z"/>
                                        </g>
                                    </svg>
                                </div>
                                <div className='text'>Locations</div>
                            </div>
                            {locations.map((loc:any, index:number) => (
                                <div className='result' key={index}>
                                    <NavLink to = {`/location/${loc.locationSlug}`} aria-label={loc.locationName} title={loc.locationName}>
                                        <div className='location-name'>{loc.locationName}</div>
                                    </NavLink>
                                </div>
                            ))}     
                        </div>)
                    }
                    {categories?.length > 0 &&
                        (<div className='categories'>
                            <div className='title'>
                                <div className='icon'>
                                    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path className='c1' d="m7.24 2h-1.9c-2.19 0-3.34 1.15-3.34 3.33v1.9c0 2.18 1.15 3.33 3.33 3.33h1.9c2.18 0 3.33-1.15 3.33-3.33v-1.9c.01-2.18-1.14-3.33-3.32-3.33z"/>
                                            <path className='c2' d="m18.6695 2h-1.9c-2.18 0-3.33 1.15-3.33 3.33v1.9c0 2.18 1.15 3.33 3.33 3.33h1.9c2.18 0 3.33-1.15 3.33-3.33v-1.9c0-2.18-1.15-3.33-3.33-3.33z"/>
                                            <path className='c1' d="m18.6695 13.4297h-1.9c-2.18 0-3.33 1.15-3.33 3.33v1.9c0 2.18 1.15 3.33 3.33 3.33h1.9c2.18 0 3.33-1.15 3.33-3.33v-1.9c0-2.18-1.15-3.33-3.33-3.33z"/>
                                            <path className='c2' d="m7.24 13.4297h-1.9c-2.19 0-3.34 1.15-3.34 3.33v1.9c0 2.19 1.15 3.34 3.33 3.34h1.9c2.18 0 3.33-1.15 3.33-3.33v-1.9c.01-2.19-1.14-3.34-3.32-3.34z"/>
                                        </g>
                                    </svg>
                                </div>
                                <div className='text'>Categories</div>
                            </div>
                            {categories.map((cat:any, index:number) => (
                                <div className='result' key={index}>
                                    <NavLink to = {`/categories/${cat.categorySlug}`} aria-label={cat.categoryName} title={cat.categoryName}>
                                        <div className='category-name'>{cat.categoryName}</div>
                                    </NavLink>
                                </div>
                            ))}
                            </div>
                        )        
                    }
                </div>
                }          
            </div>
            <div ref={overlayRef} className='overlay' onClick={ () => handleClickOverlay()}></div>
        </>
    );
};

export default Search;