const radioCategories = [
    { 
        categorySlug: 'greek-hits', 
        categoryName: 'Ελληνικές Επιτυχίες',
        categoryNameEng: 'Greek Hits',
        categoryNameUpper: 'ΕΛΛΗΝΙΚΕΣ ΕΠΙΤΥΧΙΕΣ',
        categoryNameEngUpper: 'GREEK HITS',
        order: 1
    },
    { 
        categorySlug: 'greek-laiko', 
        categoryName: 'Ελληνικά Λαϊκά',
        categoryNameEng: 'Greek Laiko',
        categoryNameUpper: 'ΕΛΛΗΝΙΚΑ ΛΑΙΚΑ',
        categoryNameEngUpper: 'GREEK LAIKO',
        order: 2
    },
    { 
        categorySlug: 'eclectic-ballads', 
        categoryName: 'Έντεχνη & Μπαλάντες',
        categoryNameEng: 'Eclectic & Ballads', 
        categoryNameUpper: 'ΈΝΤΕΧΝΗ & ΜΠΑΛΑΝΤΕΣ',
        categoryNameEngUpper: 'ECLECTIC & BALLADS',
        order: 3
    },
    { 
        categorySlug: 'various', 
        categoryName: 'Διάφορα',
        categoryNameEng: 'Various',
        categoryNameUpper: 'ΔΙΑΦΟΡΑ',
        categoryNameEngUpper: 'VARIOUS',
        order: 4
    },
    { 
        categorySlug: 'traditional', 
        categoryName: 'Παραδοσιακά',
        categoryNameEng: 'Traditional', 
        categoryNameUpper: 'ΠΑΡΑΔΟΣΙΑΚΑ',
        categoryNameEngUpper: 'TRADITIONAL', 
        order: 5
    },
    { 
        categorySlug: 'adult-contemporary-dance', 
        categoryName: 'Ξένη Μουσική & Dance',
        categoryNameEng: 'Adult Contemporary Dance', 
        categoryNameUpper: 'ΞΕΝΗ ΜΟΥΣΙΚΗ & DANCE',
        categoryNameEngUpper: 'ADULT CONTEMPORARY DANCE', 
        order: 6
    },
    { 
        categorySlug: 'rock', 
        categoryName: 'Ροκ',
        categoryNameEng: 'Rock',
        categoryNameUpper: 'ΡΟΚ',
        categoryNameEngUpper: 'ROCK', 
        order: 7
    },
    { 
        categorySlug: 'sophisticated', 
        categoryName: 'Sophisticated',
        categoryNameEng: 'Sophisticated', 
        categoryNameUpper: 'SOPHISTICATED',
        categoryNameEngUpper: 'SOPHISTICATED', 
        order: 8
    },
    { 
        categorySlug: 'news', 
        categoryName: 'Ειδήσεις & Μουσική', 
        categoryNameEng: 'News',
        categoryNameUpper: 'ΕΙΔΗΣΕΙΣ & ΜΟΥΣΙΚΗ',
        categoryNameEngUpper: 'NEWS', 
        order: 9
    },
    { 
        categorySlug: 'sports', 
        categoryName: 'Αθλητικά Νέα',
        categoryNameEng: 'Sports', 
        categoryNameUpper: 'ΑΘΛΗΤΙΚΑ ΝΕΑ',
        categoryNameEngUpper: 'SPORTS', 
        order: 10
    },
    { 
        categorySlug: 'religious', 
        categoryName: 'Θρησκευτικά',
        categoryNameEng: 'Religious', 
        categoryNameUpper: 'ΘΡΗΣΚΕΥΤΙΚΑ',
        categoryNameEngUpper: 'RELIGIOUS', 
        order: 11
    },
];

export function getRadioCategories () {
    return radioCategories.sort((a, b) => a.order - b.order);
}

export function getRadioCategoriesByName (name: string) {
    let nameLower = name.toLocaleLowerCase();
    const regex = new RegExp(nameLower, 'u');
    return radioCategories.filter(c => 
        c.categoryName.toLocaleLowerCase().match(regex) 
    || c.categoryNameUpper.toLocaleLowerCase().match(regex)
    || c.categoryNameEng.toLocaleLowerCase().match(regex)
    || c.categoryNameEngUpper.toLocaleLowerCase().match(regex));
}