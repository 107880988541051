export function getRandomNumberInRange(min: number, max: number): number {
  // Ensure that the input is valid (min <= max)
  if (min > max) {
    [min, max] = [max, min]; // Swap values using destructuring assignment
  }

  // Calculate the range and generate the random number within the range
  const range = max - min + 1; // Adding 1 to include the upper bound
  const randomInRange = Math.floor(Math.random() * range) + min;

  return randomInRange;
}