import React, {useEffect, useState} from 'react';
import RadioStation from '../components/radioStation/radio-station';
import api from '../api/api';
import {RadioProps} from '../contexts/appContext';

const Popular: React.FC = () => {
    const [items, setItems] = useState<RadioProps[]>([]);

    useEffect(() => {
        api({
            url: `radios/popular`,
            method: "GET"
        }).then ((response) => {
            if (response.status === 200 && response.data) {
                setItems(response.data);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, [])

    return (
        <>
            <div className='page-title'>Δημοφιλή</div>
            <div className='radio-station-list'>
                {items.map((radio) => (
                    <RadioStation key = {radio.id} {...radio}/>
                ))}
            </div>
        </>
    );
};

export default Popular;