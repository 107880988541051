import React from 'react';
import { NavLink } from 'react-router-dom';
import './shortcuts.css';

interface ShortcutsProps {
    items?: Shortcut[];
}

interface Shortcut {
    name: string;
    icon?: string;
    order?: number;
}

const Shortcuts: React.FC<ShortcutsProps> = () => {
    var items = [
        { name: 'Ροκ', icon: '', url: '/categories/rock' },
        { name: 'Αθλητικά Νέα', icon: '', url: '/categories/sports' },
        { name: 'Αθήνα', icon: '', url: '/location/athens' },
        { name: 'Θεσσαλονίκη', icon: '', url: '/location/thessaloniki' },
        { name: 'Κύπρος', icon: '', url: '/location/cyprus' },
    ]

    return (
        <div className='shortcuts'>
            <div className='shortcuts-title'>Συντομεύσεις</div>
            <div className='shortcuts-body'>
                {items.map((item, index) => (
                    <NavLink key={index} to = {item.url} aria-label={item.name} title={item.name}>
                        <div className='shortcut-item'>
                            { item.icon &&
                                <div className='image'>
                                    <img src={item.icon} alt={item.name}/>
                                </div>
                            }
                            <div className='name'>{item.name}</div>
                        </div>
                    </NavLink>
                ))}
            </div>
        </div>
    )
}

export default Shortcuts;