import React from 'react';
import './music-bars.css';

const MusicBars: React.FC = () => {
    return (
        <div className='music-bars'>
            <div className="bar bar1"></div>
            <div className="bar bar2"></div>
            <div className="bar bar3"></div>
            <div className="bar bar4"></div>
        </div>
    );
};

export default MusicBars;