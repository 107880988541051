import React, {useState, useEffect} from 'react';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import {getRadioCategories } from '../api/radioCategories';
import RadioStation from '../components/radioStation/radio-station';
import {useTimeout} from '../hooks/useTimeout';
import {getRandomNumberInRange} from '../utils/utils';
import api from '../api/api';
import {RadioProps} from '../contexts/appContext';

const Categories: React.FC = () => {
    const [items, setItems] = useState<RadioProps[]>([]);
    const [categoryLinkLoading, setCategoryLinkLoading] = useState<boolean>(false);
    const {categoryId} = useParams();
    const navigate = useNavigate();

    var radioCategories = getRadioCategories();
    
    useEffect(() => {
        if (categoryId === undefined) {
            navigate("greek-hits");
        }

        if (categoryId !== undefined) {
            api({
                url: `radios/category/${categoryId}`,
                method: "GET"
            }).then ((response) => {
                if(response.status === 200 && response.data) {
                    setItems(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }

    }, [categoryId, navigate]);

    useTimeout(() => {
        setCategoryLinkLoading(true);
    }, 300);

    const renderSkeletonLink = (categorySlug: string) => {
        return (
            <div key={categorySlug} className='skeleton-link-item' style={{width: `${getRandomNumberInRange(50, 150)}px`}}></div>
        )
    }

    return (
        <div>
            <div className='page-title'>Κατηγορίες</div>
            <div className='category-items-wrapper'>
                {radioCategories.map((radioCat) => (
                    (!categoryLinkLoading) ? renderSkeletonLink(radioCat.categorySlug) :
                    (<div key={radioCat.categorySlug} className='category-item'>
                        <NavLink to = {radioCat.categorySlug} aria-label={radioCat.categoryName} title={radioCat.categoryName}>
                            {radioCat.categoryName}
                        </NavLink>
                    </div>)
                ))}
            </div>
            <div className='radio-station-list'>
                {items.map((radio) => (
                    <RadioStation key = {radio.id} {...radio}/>
                ))}
            </div>
        </div>
    );
};

export default Categories;