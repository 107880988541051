import React, {useState, useEffect} from 'react';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import {getRadioLocations} from '../api/radioLocations';
import RadioStation from '../components/radioStation/radio-station';
import {useTimeout} from '../hooks/useTimeout';
import {getRandomNumberInRange} from '../utils/utils';
import api from '../api/api';
import {RadioProps} from '../contexts/appContext';

const Location: React.FC = () => {
    const [items, setItems] = useState<RadioProps[]>([]);
    const [locationLinkLoading, setLocationLinkLoading] = useState<boolean>(false);
    const { locationId } = useParams();
    const navigate = useNavigate();

    var radioLocations = getRadioLocations();

    useEffect(() => {

        if (locationId === undefined) {
            navigate("athens");
        }

        if (locationId !== undefined) {
            api({
                url: `radios/location/${locationId}`,
                method: "GET"
            }).then ((response) => {
                if(response.status === 200 && response.data) {
                    setItems(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }

    }, [locationId, navigate]);

    useTimeout(() => {
        setLocationLinkLoading(true);
    }, 300);

    const renderSkeletonLink = (locationSlug: string) => {
        return (
            <div key={locationSlug} className='skeleton-link-item' style={{width: `${getRandomNumberInRange(50, 150)}px`}}></div>
        )
    }

    return (
        <>
            <div className='page-title'>Περιοχές</div>
            <div className='location-items-wrapper'>
                {radioLocations.map((radioLoc) => (
                    (!locationLinkLoading) ? renderSkeletonLink(radioLoc.locationSlug) :
                    (<div key={radioLoc.locationSlug} className='location-item'>
                        <NavLink to = {radioLoc.locationSlug} aria-label= {radioLoc.locationName} title= {radioLoc.locationName}>
                            {radioLoc.locationName}
                        </NavLink>
                    </div>)
                ))}
            </div>
            <div className='radio-station-list'>
                {items.map((radio) => (
                    <RadioStation key = {radio.id} {...radio}/>
                ))}
            </div>
        </>
    );
};

export default Location;