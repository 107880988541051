import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './layout/page-layout';
import { Home, Categories, Location, Popular, RecentlyPlayed, Favourites } from './pages';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element = { <Layout/>}>
          <Route index element={<Home/>}/>
          <Route path="/categories" element={ <Categories/> } >
            <Route path=":categoryId" element={ <Categories/> } />
          </Route>
          <Route path="/location" element={ <Location/> } >
            <Route path=":locationId" element={ <Location/> } />
          </Route>
          <Route path="/popular" element={ <Popular/> } />
          <Route path="/recently-played" element={ <RecentlyPlayed/> } />
          <Route path="/favourites" element={ <Favourites/> } />
          
          
          {/* <Route path="*" element={<NavigateTo404 />} /> */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;