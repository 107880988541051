import React, {useState, useRef, useEffect, useContext, useCallback} from 'react';
import { AppContext, RadioProps } from '../../contexts/appContext';
import Volume from './volume';
import './player.css';
import api from '../../api/api';
import toast from 'react-hot-toast';
import { BrokenStream } from '../../assets';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import Hls from 'hls.js';


const Player2: React.FC = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const {toggleFavouriteRadio, favouriteRadios, currentRadio, updateCurrentRadio, addRadioToRecentlyPlayed } = useContext(AppContext);
    //const audioContext = useRef<AudioContext>();
    //const audioSource = useRef<MediaElementAudioSourceNode>();
    //const audioAnalyser = useRef<AnalyserNode>();
    const audio = useRef<HTMLAudioElement>(new Audio());
    //const [streamUrl, setStreamUrl] = useState<string>('');
    //const [volume, setVolume] = useState<any>(0.5);
    const [muted, setMuted] = useState<boolean>(false);
    audio.current.crossOrigin = 'anonymous';
    //var dataArray: any = null;

    // const handleLoadedData = () => {
    //     audio.current.play();
    // }

    if ('mediaSession' in navigator) {
        navigator.mediaSession.setActionHandler('play', function() {
            updateCurrentRadio?.(currentRadio?.isPlaying as boolean, currentRadio as RadioProps);
        });

        navigator.mediaSession.setActionHandler('pause', function() {
            updateCurrentRadio?.(currentRadio?.isPlaying as boolean, currentRadio as RadioProps);
        });
    
        navigator.mediaSession.setActionHandler('stop', function() {
            updateCurrentRadio?.(currentRadio?.isPlaying as boolean, currentRadio as RadioProps);
        });

        // Set initial playback state
        navigator.mediaSession.playbackState = 'none';

    }


    const playAudio = useCallback(async () => {
        var extension = currentRadio?.streamUrl !== undefined
            ?  currentRadio.streamUrl.split('.').pop()
            : "";

            if (extension === 'm3u8') {
                if(Hls.isSupported()){
                    console.log("supported");
                    const hls = new Hls();
                    hls.loadSource(currentRadio?.streamUrl as string);
                    hls.attachMedia(audio.current);
                    hls.on(Hls.Events.MANIFEST_PARSED, function() {
                        audio.current.play();
                    })
                    console.log("supporting...");
                }
            }
            else {
                if(audio.current) {
                    if(currentRadio?.streamUrl) {
                        audio.current.src = currentRadio?.streamUrl //currentStreamUrl;
                        await audio.current.play().then(() => {
                            //setStreamUrl(currentStreamUrl);
                        });
                    }
                }
            }

            if ('mediaSession' in navigator) {
                navigator.mediaSession.metadata = new MediaMetadata({
                    title: currentRadio?.name,
                    artwork: [
                        { src: currentRadio?.iconUrl as string }
                    ]});
            }

    }, [currentRadio]);

    useEffect(() => {
        if (currentRadio?.id !== undefined) {

            if(containerRef.current){
                containerRef.current.classList.add('active');
            }

            new Promise(function(resolve, reject){
                audio.current.pause();
                audio.current.currentTime = 0;
            }).then(()=>{
                playAudio();
            });
            
            // if (currentRadio?.streamUrl) {
            //     audio.current.src = currentRadio?.streamUrl;
            //     audio.current.play().catch(e => e);
            // }

            
        }

        if (currentRadio !== undefined) {
            addRadioToRecentlyPlayed?.(currentRadio as RadioProps);
        }

        return () => {
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentRadio, playAudio]);

    

    useEffect(() => {
        if(currentRadio?.isPlaying !== undefined && currentRadio?.isPlaying === true) {
            if(audio.current.paused) {
                playAudio().then(() => {}).catch(e => e);
            }
        }

        if(currentRadio?.isPlaying !== undefined && currentRadio?.isPlaying === false) {
            pauseAudio();
        }
    }, [currentRadio?.id, currentRadio?.isPlaying, playAudio])


    const pauseAudio = async () => {
        if(audio.current && !audio.current.paused) {
            try {
                audio.current.pause();
                audio.current.currentTime = 0;
                audio.current.src = '';
            } catch (error) {
                
            }
        }
        // if(audioAnalyser.current){
        //     audioAnalyser.current.disconnect();
        // }
    };

    // const handleAudioContext = () => {
    //     if(audioContext.current == null 
    //         || audioContext.current === undefined 
    //         || audioContext.current.state === 'suspended' 
    //         || audioContext.current.state === 'closed'){
    //         audioContext.current = new AudioContext();
    //         console.log("Audio Context is initialing");
    //     }
    //     else {
    //         audioContext.current.resume();
    //         console.log("Audio Context is resuming");
    //     }

    //     if(audioSource.current == null){
    //         audioSource.current = audioContext.current.createMediaElementSource(audio.current);
    //         audioSource.current.connect(audioContext.current.destination);
    //     }
    //     else { 
    //          audioSource.current.connect(audioContext.current.destination);
    //     }

    //     //Process Audio Data
    //     if(audioAnalyser.current == null) {
    //         audioAnalyser.current = audioContext.current.createAnalyser();
    //         audioSource.current.connect(audioAnalyser.current);
    //     }
    //     else {
    //          audioSource.current.connect(audioAnalyser.current);
    //     }
    // }

    // const startCapturingData = (analyser: any, dataArray: Uint8Array) => {

    //     function captureAudioData() { 
    //         //analyser.getByteTimeDomainData(dataArray);
    //         analyser.getByteFrequencyData(dataArray);  
    //         // Process the audio data here...
    //         console.log(dataArray); // Example: Logging the audio data to the console
    //         //requestAnimationFrame(captureAudioData);
    //         setTimeout(captureAudioData, 200);
    //     }

    //     // Begin capturing audio data continuously
    //     captureAudioData();
    // };

    const handleVolumeChange = (vol: string) => {
        if(audio.current){
            
            //setVolume(vol);
            //handleVolumeMuted(Number(volume) > 0 ? false : true);
            audio.current.volume = Number(vol); 

            if (Number(vol) === 0) {
                audio.current.muted = muted;
            }
        }
    }

            
    

    const handleVolumeMuted = (state: boolean) => {
        if(audio.current){
            setMuted(state);
            audio.current.muted = state;
        }
    }

    const handlePlayPause = () => {
        updateCurrentRadio?.(currentRadio?.isPlaying as boolean, currentRadio as RadioProps);
    }

    const handleBrokenRadio = () => {
        let message = `Ο σταθμός ${currentRadio?.name} δεν λειτουργεί`;

        api({
            url: `radio/${currentRadio?.id}/broken`,
            method: "POST"
        }).then ((response) => {
            if(response.status === 200 && response.data) {
                toast.error(message);
                console.log(response.data);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const handleFavorite = (radio: RadioProps, operation: 'add' | 'remove') => {
        let message = (operation === 'add') 
            ? `Προστέθηκε ο ${radio.name} στα αγαπημένα` 
            : (operation === 'remove') 
                ? `Αφαιρέθηκε ο ${radio.name} από τα αγαπημένα` 
                : '';
        toast.success(message);
        toggleFavouriteRadio?.(radio, operation);
    }

    const isRadioFavourite = favouriteRadios.some((item) => item.id === currentRadio?.id);

    const {isMobile} = useDeviceDetect();

    return(
        <>
            <div ref={containerRef} className={`player-widget-inner ${isMobile ? 'mob' : ''}`}>
                {!isRadioFavourite &&
                    <div className='radio is-favourite' title='Προσθήκη στα αγαπημένα' onClick={ () => handleFavorite(currentRadio as RadioProps, 'add')}>
                        <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m12.62 20.81c-.34.12-.9.12-1.24 0-2.9-.99-9.38-5.12-9.38-12.12002 0-3.09 2.49-5.59 5.56-5.59 1.82 0 3.43.88 4.44 2.24 1.01-1.36 2.63-2.24 4.44-2.24 3.07 0 5.56 2.5 5.56 5.59 0 7.00002-6.48 11.13002-9.38 12.12002z"/>
                        </svg>
                    </div>
                }
                {isRadioFavourite &&
                    <div className='radio is-favourite selected' title='Αφαίρεση από τα αγαπημένα' onClick={ () => handleFavorite(currentRadio as RadioProps, 'remove')}>
                        <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m12.62 20.81c-.34.12-.9.12-1.24 0-2.9-.99-9.38-5.12-9.38-12.12002 0-3.09 2.49-5.59 5.56-5.59 1.82 0 3.43.88 4.44 2.24 1.01-1.36 2.63-2.24 4.44-2.24 3.07 0 5.56 2.5 5.56 5.59 0 7.00002-6.48 11.13002-9.38 12.12002z"/>
                        </svg>
                    </div>
                } 
                <div className='radio-broken' onClick={() => handleBrokenRadio()}>
                    <img src= {BrokenStream} alt = 'Broken Radio' title='Ο σταθμός δεν λειτουργεί'/> 
                </div>
                <div className='radio-icon'>
                    <img src={currentRadio?.iconUrl} alt={currentRadio?.name}/>
                </div>
                <div className='action' title={`${currentRadio?.isPlaying ? 'Παύση' : 'Αναπαραγωγή'}`} onClick={ () => handlePlayPause()}>
                    <svg className={`icon ${currentRadio?.isPlaying ? 'is-playing' : ''}`}  viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M716.667 383.333C707.467 383.333 700 390.8 700 400C700 565.4 565.4 700 400 700C390.8 700 383.333 707.467 383.333 716.667C383.333 725.867 390.8 733.334 400 733.334C583.8 733.334 733.333 583.802 733.333 400.002C733.333 390.8 725.867 383.333 716.667 383.333Z" fill="#0D5EAF"/>
                        <path d="M400 100C409.2 100 416.667 92.5328 416.667 83.3328C416.667 74.1328 409.2 66.6672 400 66.6672C216.2 66.6672 66.6672 216.2 66.6672 400C66.6672 409.2 74.1344 416.667 83.3344 416.667C92.5344 416.667 100 409.2 100 400C100 234.6 234.6 100 400 100Z" fill="#0D5EAF"/>
                        <path d="M400 0C179.433 0 0 179.433 0 400C0 620.567 179.433 800 400 800C620.567 800 800 620.567 800 400C800 179.433 620.567 0 400 0ZM400 766.667C197.833 766.667 33.3328 602.167 33.3328 400C33.3328 197.833 197.833 33.3328 400 33.3328C602.167 33.3328 766.667 197.833 766.667 400C766.667 602.167 602.167 766.667 400 766.667Z" fill="#0D5EAF"/>
                    </svg>
                    {!currentRadio?.isPlaying &&
                        <svg className='play-pause' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10"/>
                            <path d="M15.4137 10.941C16.1954 11.4026 16.1954 12.5974 15.4137 13.059L10.6935 15.8458C9.93371 16.2944 9 15.7105 9 14.7868L9 9.21316C9 8.28947 9.93371 7.70561 10.6935 8.15419L15.4137 10.941Z"/>
                        </svg>
                    }
                    {currentRadio?.isPlaying &&
                        <svg className='play-pause' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10"/>
                            <path d="M8 9.5C8 9.03406 8 8.80109 8.07612 8.61732C8.17761 8.37229 8.37229 8.17761 8.61732 8.07612C8.80109 8 9.03406 8 9.5 8C9.96594 8 10.1989 8 10.3827 8.07612C10.6277 8.17761 10.8224 8.37229 10.9239 8.61732C11 8.80109 11 9.03406 11 9.5V14.5C11 14.9659 11 15.1989 10.9239 15.3827C10.8224 15.6277 10.6277 15.8224 10.3827 15.9239C10.1989 16 9.96594 16 9.5 16C9.03406 16 8.80109 16 8.61732 15.9239C8.37229 15.8224 8.17761 15.6277 8.07612 15.3827C8 15.1989 8 14.9659 8 14.5V9.5Z" />
                            <path d="M13 9.5C13 9.03406 13 8.80109 13.0761 8.61732C13.1776 8.37229 13.3723 8.17761 13.6173 8.07612C13.8011 8 14.0341 8 14.5 8C14.9659 8 15.1989 8 15.3827 8.07612C15.6277 8.17761 15.8224 8.37229 15.9239 8.61732C16 8.80109 16 9.03406 16 9.5V14.5C16 14.9659 16 15.1989 15.9239 15.3827C15.8224 15.6277 15.6277 15.8224 15.3827 15.9239C15.1989 16 14.9659 16 14.5 16C14.0341 16 13.8011 16 13.6173 15.9239C13.3723 15.8224 13.1776 15.6277 13.0761 15.3827C13 15.1989 13 14.9659 13 14.5V9.5Z" />
                        </svg>
                    }    
                </div>
                {/* <Volume value = {volume} muted = {muted} onChange={handleVolumeChange} onMuted={handleVolumeMuted}/> */}
                {!isMobile &&
                    <Volume onChange={handleVolumeChange} onMuted={handleVolumeMuted}/> 
                } 
            </div>
        </>
    )
};

export default Player2;