import React, {createContext, useState, useEffect, ReactNode} from 'react';

export interface RadioProps {
    id: string,
    name: string,
    category: string,
    categorySlug: string,
    location: string,
    locationSlug: string,
    iconUrl: string,
    city?: string,
    streamUrl?: string,
    telephone?: string,
    fax?: string,
    address?: string,
    email?: string,
    isPlaying?: boolean
}

interface AppContextProps {
    currentRadio?: RadioProps;
    updateCurrentRadio?: (isPlayingMode: boolean, selectedRadio: RadioProps) => void;
    currentStreamUrl: string,
    updateStreamUrl?: (newStreamUrl: string) => void;
    isPlayingMode: boolean;
    updatePlayingMode?: (isPlayingMode: boolean) => void;
    toggleFavouriteRadio?: (radio: RadioProps, state: 'add' | 'remove') => void;
    favouriteRadios: RadioProps[];
    addRadioToRecentlyPlayed?: (radio: RadioProps) => void;
    recentlyPlayedRadios: RadioProps[]
}


// export const AppContext = createContext<{currentStreamUrl: string, updateStreamUrl? : (newStreamUrl: string) => void }>({currentStreamUrl: ''});

export const AppContext = createContext<AppContextProps>({
    currentStreamUrl: '', 
    isPlayingMode: false,
    favouriteRadios: [], 
    recentlyPlayedRadios: [],
});



interface AppProviderProps {
    children: ReactNode;
}



export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [currentStreamUrl, setStreamUrl] = useState('');
    
    const favRadios = localStorage.getItem('favouriteRadios');
    const favoRadios = (favRadios !== null) ? JSON.parse(favRadios) : [];
    
    const recPlayedRadios = localStorage.getItem('recentlyPlayedRadios'); 
    const recePlayedRadios = (recPlayedRadios !== null) ? JSON.parse(recPlayedRadios) : [];

    
    const [favouriteRadios, setFavouriteRadios] = useState<RadioProps[]>(favoRadios);
    const [recentlyPlayedRadios, setRecentlyPlayedRadios] = useState<RadioProps[]>(recePlayedRadios);

    const [isPlayingMode, setIsPlayingMode] = useState<boolean>(false);
    const [currentRadio, setCurrentRadio] = useState<RadioProps>();

    //Update the value in local storage whenever it changes
    useEffect(() => {
        localStorage.setItem('currentStreamUrl', JSON.stringify(currentStreamUrl));
        localStorage.setItem('favouriteRadios', JSON.stringify(favouriteRadios));
        localStorage.setItem('recentlyPlayedRadios', JSON.stringify(recentlyPlayedRadios));
    }, [favouriteRadios, recentlyPlayedRadios, currentStreamUrl]);

    const updateStreamUrl = (newStreamUrl: string) => {
        setStreamUrl(newStreamUrl);
    };

    const updateCurrentRadio = (isPlayingMode : boolean, selectedRadio: RadioProps) => {
            selectedRadio.isPlaying = !isPlayingMode;
            setCurrentRadio(selectedRadio);
            setIsPlayingMode(!isPlayingMode);
    }

    const updatePlayingMode = (isPlayingMode: boolean) => {
        setIsPlayingMode(!isPlayingMode);
        let radio = currentRadio;
        
        if (radio?.isPlaying !== undefined) { 
            radio.isPlaying = !isPlayingMode;
            setCurrentRadio(radio);
        }
        
    }

    const toggleFavouriteRadio = (radio: RadioProps, state: 'add' | 'remove') => {
        if (state === 'add') {
            const updateRadios = [radio, ...favouriteRadios]
            setFavouriteRadios(updateRadios);
        }

        if (state === 'remove') {
            setFavouriteRadios(favouriteRadios?.filter((item) => item.id !== radio.id));
        } 
    }

    const addRadioToRecentlyPlayed = (radio: RadioProps) => {
        const newRecentlyPlayedRadios = recentlyPlayedRadios?.filter(item => item.id !== radio.id);
        setRecentlyPlayedRadios([radio,...newRecentlyPlayedRadios]);
    }

    return (
        <AppContext.Provider value = {{currentStreamUrl, currentRadio, updateCurrentRadio, isPlayingMode, updateStreamUrl, updatePlayingMode, toggleFavouriteRadio, favouriteRadios, addRadioToRecentlyPlayed, recentlyPlayedRadios}}>
            {children}
        </AppContext.Provider>
    )
};

export const AppConsumer = AppContext.Consumer;