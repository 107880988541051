const radioLocations = [
    { 
        locationSlug: 'athens', 
        locationName: 'Αθήνα', 
        locationNameEng: 'Athens', 
        locationNameUpper: 'ΑΘΗΝΑ', 
        locationNameEngUpper: 'ATHENS', 
        order: 1
    },
    { 
        locationSlug: 'thessaloniki', 
        locationName: 'Θεσσαλονίκη', 
        locationNameEng: 'Thessaloniki', 
        locationNameUpper: 'ΘΕΣΣΑΛΟΝΙΚΗ', 
        locationNameEngUpper: 'THESSALONIKI', 
        order: 2
    },
    { 
        locationSlug: 'epirus', 
        locationName: 'Ήπειρος', 
        locationNameEng: 'Epirus', 
        locationNameUpper: 'ΗΠΕΙΡΟΣ', 
        locationNameEngUpper: 'EPIRUS', 
        order: 3
    },
    { 
        locationSlug: 'thessaly', 
        locationName: 'Θεσσαλία', 
        locationNameEng: 'Thessaly', 
        locationNameUpper: 'ΘΕΣΣΑΛΙΑ', 
        locationNameEngUpper: 'THESSALY', 
        order: 4
    },
    { 
        locationSlug: 'thrace', 
        locationName: 'Θράκη', 
        locationNameEng: 'Thrace', 
        locationNameUpper: 'ΘΡΑΚΗ', 
        locationNameEngUpper: 'THRACE', 
        order: 5
    },
    { 
        locationSlug: 'crete', 
        locationName: 'Κρήτη', 
        locationNameEng: 'Crete', 
        locationNameUpper: 'ΚΡΗΤΗ', 
        locationNameEngUpper: 'CRETE', 
        order: 6
    },
    { 
        locationSlug: 'macedonia', 
        locationName: 'Μακεδονία', 
        locationNameEng: 'Macedonia', 
        locationNameUpper: 'ΜΑΚΕΔΟΝΙΑ', 
        locationNameEngUpper: 'MACEDONIA', 
        order: 7
    },
    { 
        locationSlug: 'aegean', 
        locationName: 'Αιγαίο', 
        locationNameEng: 'Aegean', 
        locationNameUpper: 'ΑΙΓΑΙΟ', 
        locationNameEngUpper: 'AEGEAN', 
        order: 8
    },
    { 
        locationSlug: 'ionian', 
        locationName: 'Ιόνιο', 
        locationNameEng: 'Ionian', 
        locationNameUpper: 'ΙΟΝΙΟ', 
        locationNameEngUpper: 'IONIAN', 
        order: 9
    },
    { 
        locationSlug: 'peloponnesus', 
        locationName: 'Πελοπόννησος', 
        locationNameEng: 'Peloponnesus', 
        locationNameUpper: 'ΠΕΛΟΠΟΝΝΗΣΟΣ', 
        locationNameEngUpper: 'PELOPONNESUS', 
        order: 10
    },
    { 
        locationSlug: 'sterea', 
        locationName: 'Στερεά Ελλάδα', 
        locationNameEng: 'Sterea', 
        locationNameUpper: 'ΣΤΕΡΕΑ ΕΛΛΑΔΑ', 
        locationNameEngUpper: 'STEREA', 
        order: 11
    },
    { 
        locationSlug: 'cyprus', 
        locationName: 'Κύπρος', 
        locationNameEng: 'Cyprus', 
        locationNameUpper: 'ΚΥΠΡΟΣ', 
        locationNameEngUpper: 'CYPRUS', 
        order: 12
    },
    { 
        locationSlug: 'internet', 
        locationName: 'Internet Radios', 
        locationNameEng: 'Internet Radios', 
        locationNameUpper: 'INTERNET RADIOS', 
        locationNameEngUpper: 'INTERNET RADIOS', 
        order: 13
    }
];

export function getRadioLocations () {
    return radioLocations.sort((a, b) => a.order - b.order);
}

export function getRadioLocationsByName (name:string) {
    let nameLower = name.toLocaleLowerCase();
    const regex = new RegExp(nameLower, 'u');
    return radioLocations.filter(l => 
        l.locationName.toLocaleLowerCase().match(regex) 
    || l.locationNameUpper.toLocaleLowerCase().match(regex)
    || l.locationNameEng.toLocaleLowerCase().match(regex)
    || l.locationNameEngUpper.toLocaleLowerCase().match(regex));
}