import { useRef, useEffect, useCallback, useLayoutEffect } from 'react';

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function useTimeout(callback: () => void, delay: number | undefined) {
    const savedCallback = useRef(callback);
    const timeoutId = useRef<NodeJS.Timeout | null>(null);

    useIsomorphicLayoutEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Create a memoized resetTimeout function
    const resetTimeout = useCallback(() => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }

        timeoutId.current = setTimeout(() => {
            savedCallback.current();
        }, delay);

    }, [delay]);


    useEffect(() => {
        resetTimeout();
        return () => {
          if (timeoutId.current) {
            clearTimeout(timeoutId.current);
          }
        };
      }, [delay, resetTimeout]);
    
    return resetTimeout;
}