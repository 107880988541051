import React, {useEffect, useState, useContext} from 'react';
import api from '../../api/api';
import {AppContext, RadioProps} from '../../contexts/appContext';
import {useTimeout} from '../../hooks/useTimeout';
import {getRandomNumberInRange} from '../../utils/utils';
import './category-widget.css';
import {trackCustomEvent} from '../../utils/gtm';

interface CategoryWidgetProps {
    title: string;
    categoryId: string;
    numOfDisplayedItems: number;
}

const CategoryWidget: React.FC<CategoryWidgetProps> = ({
    title,
    categoryId,
    numOfDisplayedItems = 10
}) => {
    const [items, setItems] = useState<RadioProps[]>([]);
    const [isWidgetItemLoading, setIsWidgetItemLoading] = useState<boolean>(false);
    const {currentRadio,updateCurrentRadio} = useContext(AppContext);
    const [isPlayingMode, setIsPlayingMode] = useState<boolean>(false);

    const handleSelectedRadio = (streamUrl: string, item: RadioProps) => {
        if(currentRadio === undefined){
            updateCurrentRadio?.(isPlayingMode, item);
            setIsPlayingMode(!isPlayingMode);

            trackCustomEvent({eventName:`Station - ${item.name}`});
        }
        else if (currentRadio?.id === item.id){
            updateCurrentRadio?.(isPlayingMode, item);
            setIsPlayingMode(!isPlayingMode);
        }
        else if (currentRadio?.id !== item.id){
            updateCurrentRadio?.(false, item);
            setIsPlayingMode(true);

            trackCustomEvent({eventName:`Station - ${item.name}`});
        }
    }

    useTimeout(() => {
        setIsWidgetItemLoading(true);
    }, getRandomNumberInRange(100, 300));

    useEffect(() => {
        api({
            url: `radios/category/${categoryId}`,
            method: "GET"
        }).then ((response) => {
            if(response.status === 200 && response.data) {
                setItems(response.data);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, [categoryId]);

    const renderSkeletonWidgetItem = (widgetId: string) => {
        return (
            <div key={widgetId} className='skeleton-category-widget-item'>
                <div className='skeleton-icon'></div>
                <div className='skeleton-name'></div>
            </div>
        )
    }

    useEffect(() => {
        setIsPlayingMode(currentRadio?.isPlaying as boolean);
    }, [currentRadio?.isPlaying])

    return(
        <div className='category-widget'>
            <div className='category-widget-title'>{title}</div>
            <div className={`category-widget-list ${!isWidgetItemLoading ? 'not-loading' : ''}`}>
                {items?.map((radio) => (
                    (!isWidgetItemLoading) ? renderSkeletonWidgetItem(radio.id) :
                    (<div key={radio?.id} title={radio.name} className={`radio-item ${(currentRadio?.id === radio.id && isPlayingMode === true) ? 'active' : ''}`} onClick={ () => handleSelectedRadio(radio.streamUrl as string, radio)}>
                        <div className='radio-icon'>
                            <img src={radio.iconUrl} alt={radio.name} title={radio.name}/>
                            <div className='cover'></div>
                            <div className={`action ${(currentRadio?.id === radio.id && isPlayingMode === true) ? 'playing active' : ''}`}> {/*title={(currentRadio?.id === radio.id && isPlayingMode === true) ? 'Pause' : 'Play'}> */}
                                {(currentRadio === undefined || currentRadio?.id !== radio.id) &&
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10"/>
                                        <path d="M15.4137 10.941C16.1954 11.4026 16.1954 12.5974 15.4137 13.059L10.6935 15.8458C9.93371 16.2944 9 15.7105 9 14.7868L9 9.21316C9 8.28947 9.93371 7.70561 10.6935 8.15419L15.4137 10.941Z"/>
                                    </svg>
                                }
                                {(currentRadio?.id === radio.id && isPlayingMode === false) &&
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10"/>
                                        <path d="M15.4137 10.941C16.1954 11.4026 16.1954 12.5974 15.4137 13.059L10.6935 15.8458C9.93371 16.2944 9 15.7105 9 14.7868L9 9.21316C9 8.28947 9.93371 7.70561 10.6935 8.15419L15.4137 10.941Z"/>
                                    </svg>
                                }
                                {(currentRadio?.id === radio.id && isPlayingMode === true) && 
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10"/>
                                        <path d="M8 9.5C8 9.03406 8 8.80109 8.07612 8.61732C8.17761 8.37229 8.37229 8.17761 8.61732 8.07612C8.80109 8 9.03406 8 9.5 8C9.96594 8 10.1989 8 10.3827 8.07612C10.6277 8.17761 10.8224 8.37229 10.9239 8.61732C11 8.80109 11 9.03406 11 9.5V14.5C11 14.9659 11 15.1989 10.9239 15.3827C10.8224 15.6277 10.6277 15.8224 10.3827 15.9239C10.1989 16 9.96594 16 9.5 16C9.03406 16 8.80109 16 8.61732 15.9239C8.37229 15.8224 8.17761 15.6277 8.07612 15.3827C8 15.1989 8 14.9659 8 14.5V9.5Z" />
                                        <path d="M13 9.5C13 9.03406 13 8.80109 13.0761 8.61732C13.1776 8.37229 13.3723 8.17761 13.6173 8.07612C13.8011 8 14.0341 8 14.5 8C14.9659 8 15.1989 8 15.3827 8.07612C15.6277 8.17761 15.8224 8.37229 15.9239 8.61732C16 8.80109 16 9.03406 16 9.5V14.5C16 14.9659 16 15.1989 15.9239 15.3827C15.8224 15.6277 15.6277 15.8224 15.3827 15.9239C15.1989 16 14.9659 16 14.5 16C14.0341 16 13.8011 16 13.6173 15.9239C13.3723 15.8224 13.1776 15.6277 13.0761 15.3827C13 15.1989 13 14.9659 13 14.5V9.5Z" />
                                    </svg>
                                }
                            </div>
                        </div>
                        <div className='name' title={radio.name}>{radio.name}</div>
                    </div>)
                ))}
            </div>
        </div>
    )
};

export default CategoryWidget;